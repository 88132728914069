import { render, staticRenderFns } from "./VueBackgroundImage.vue?vue&type=template&id=787aeb6e&scoped=true&lang=pug"
import script from "./VueBackgroundImage.vue?vue&type=script&lang=js"
export * from "./VueBackgroundImage.vue?vue&type=script&lang=js"
import style0 from "./VueBackgroundImage.vue?vue&type=style&index=0&id=787aeb6e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "787aeb6e",
  null
  
)

export default component.exports