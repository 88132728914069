<template lang="pug">
  .vue-background-image
    transition(name="fade")
      div(
        key="1"
        v-if="!loaded"
      )
        slot
      div(
        key="2"
        v-else
        :class="imageClass"
        :style="computedStyle"
      )
        slot
</template>

<script>
import supportsWebP from 'supports-webp'
var PRODUCTION = process.env.NODE_ENV !== 'development'

export default {
  props: {
    imageSource: {
      type: String,
      required: true
    },
    imageClass: {
      type: String,
      required: false,
      default: ''
    },
    backgroundSize: {
      type: String,
      required: false,
      default: 'cover'
    }
  },
  data () {
    return {
      imageWidth: 0,
      imageHeight: 0,
      loaded: false,
      observer: null
    }
  },
  computed: {
    computedStyle () {
      if (this.loaded) {
        return `background: url(${this.imageSource}) no-repeat; background-size: ${this.backgroundSize}; background-position: center;`
      }

      return ''
    }
  },

  methods: {
    intersectionWatcher () {
      this.observer = new IntersectionObserver(async entries => {
        const image = entries[0]
        if (image.isIntersecting) {
          this.observer.disconnect()

          if (this.source !== '') {
            const newImg = new Image()

            if (await supportsWebP && PRODUCTION) {
              this.imageSource = this.imageSource + '.webp'
            }

            newImg.src = this.imageSource

            newImg.onload = () => {
              this.$nextTick(() => {
                this.loaded = true
              })
            }
          }
        }
      })

      this.observer.observe(this.$el)
    }
  },

  mounted () {
    this.intersectionWatcher()
  },

  destroyed () {
    this.observer.disconnect()
  }
}
</script>

<style lang="scss" scoped>
.vue-background-image {
  position: relative;

  div {
    width: 100%;
    height: 100%;
    // Fix prerender issue not adding these params
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .25s;
}

.fade-enter {
  opacity: 0;
}

.fade-leave-active {
  position: absolute;
  // z-index: -1;
}
</style>
